import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/index.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/Account/index.vue'),
    children: [
      {
        path: '/account/order',
        name: 'order',
        component: () => import('@/views/Account/order.vue')
      },
      {
        path: '/account/details',
        name: 'details',
        component: () => import('@/views/Account/details.vue')
      },
      {
        // 路由重定向
        path: '/account',
        redirect: {
          path: '/account/order',
        }
      }
    ]
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Products/index.vue')
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('@/views/ProductDetails/index.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/Cart/index.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/Checkout/index.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/Pay/index.vue')
  },
  {
    path: '/app-success',
    name: 'app-success',
    component: () => import('@/views/Pay/app-success.vue')
  },
  {
    path: '/app-failed',
    name: 'app-failed',
    component: () => import('@/views/Pay/app-failed.vue')
  },
  {
    path: '/lm-success',
    name: 'lm-success',
    component: () => import('@/views/Pay/lm-success.vue')
  },
  {
    path: '/lm-failed',
    name: 'lm-failed',
    component: () => import('@/views/Pay/lm-failed.vue')
  },
  // 配置页 ______________________________________________________________________
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/Assist/about-us.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/Assist/terms.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/Assist/return-refund-policy.vue')
  },
  {
    path: '/methods',
    name: 'methods',
    component: () => import('@/views/Assist/payment-methods.vue')
  },
  {
    path: '/shipping',
    name: 'shipping',
    component: () => import('@/views/Assist/shipping-delivery.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Assist/privacy-policy.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/Assist/contact-us.vue')
  },
  // 404 页面
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
  {
    // 路由重定向
    path: '/',
    redirect: {
      path: '/',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 每次路由跳转之后 新页面加载在x轴和y轴的初始位置
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
})

// 全局前置守卫
router.beforeEach((to, from, next) => {

  const flag = store.state.userInfo.access_token

  if (to.path === '/checkout' && !flag) {
    next('/login');
  } else if (to.path === '/pay' && !flag) {
    next('/login');
  } else if (to.path === '/account/order' && !flag) {
    next('/login');
  } else {
    next(); // 正常跳转
  }



});

export default router
